import React from 'react'
import { Translation } from 'react-i18next'

export const COMMON_TOOLTIPS = {
  addToTrackerButton: {
    tracked: (
      <div>
        Click to View
        <br />
        in Product Tracker
      </div>
    ),
    untracked: <div>Add Product to your Tracker</div>
  }
}

export const DATABASE_TOOLTIPS = {
  nullProductData: {
    unavailable: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.unavailable',
              'Product is currently unavailable on Amazon, cached data is being shown.'
            )}
          </div>
        )}
      </Translation>
    ),
    brand: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.brand',
              'Amazon does not list a brand for this particular listing.'
            )}
          </div>
        )}
      </Translation>
    ),
    seller: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.seller',
              'No sellers controlled the buy box when this particular listing was last checked.'
            )}
          </div>
        )}
      </Translation>
    ),
    category: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.category',
              'This listing does not have a parent category.'
            )}
          </div>
        )}
      </Translation>
    ),
    noBsrCategory: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noBsrCategory',
              'This listing does not have a parent category. Category shown is the highest level sub-category.'
            )}
          </div>
        )}
      </Translation>
    ),
    noSubCategories: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noSubCategories',
              'We were unable to obtain additional categories.'
            )}
          </div>
        )}
      </Translation>
    ),
    price: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.price',
              'No sellers controlled the buy box when this particular listing was last checked.'
            )}
          </div>
        )}
      </Translation>
    ),
    noFeesNet: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noFeesNet',
              "Since Amazon does not have all the information to determine this product's fees, we're unable to calculate the net after fees."
            )}
          </div>
        )}
      </Translation>
    ),
    noPriceNet: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noPriceNet',
              "Without the product price, we're unable to determine the net after fees for this product."
            )}
          </div>
        )}
      </Translation>
    ),
    noFees: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noFees',
              'Amazon did not have the product dimensions, weight and price in their database last time we checked, therefore, the fees cannot be determined.'
            )}
          </div>
        )}
      </Translation>
    ),
    weight: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.weight',
              'Amazon does not list the weight of this product, therefore, the weight and sizing tier cannot be displayed.'
            )}
          </div>
        )}
      </Translation>
    ),
    dimensions: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.dimensions',
              'Amazon did not have dimensions for this product last time we checked.'
            )}
          </div>
        )}
      </Translation>
    ),
    listedAt: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.listedAt',
              'Amazon did not have the date first available for this product last time we checked.'
            )}
          </div>
        )}
      </Translation>
    ),
    tier: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.tier',
              'Amazon does not list the dimensions and weight of this product, therefore, the sizing tier cannot be displayed.'
            )}
          </div>
        )}
      </Translation>
    ),
    reviews: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.reviews',
              'We were unable to obtain the number of reviews for this listing.'
            )}
          </div>
        )}
      </Translation>
    ),
    rating: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.rating',
              'This product did not have any reviews and therefore rating when last updated.'
            )}
          </div>
        )}
      </Translation>
    ),
    rank: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.rank',
              'Amazon does not rank this product in a parent category, only sub-categories. To compare apples to apples, sub-category ranks are not displayed in this window.'
            )}
          </div>
        )}
      </Translation>
    ),
    noBsrRank: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noBsrRank',
              'Amazon does not rank this listing in a parent category, only sub-categories. In order to compare apples to apples, sub-category ranks are not displayed in this window.'
            )}
          </div>
        )}
      </Translation>
    ),
    noLQS: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noLQS',
              'Amazon gave us an error when collecting the neccessary data to calculate LQS for this product.'
            )}
          </div>
        )}
      </Translation>
    ),
    noEstSales: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noEstSales',
              "We're currently working on improving our algorithms for this particular category, estimated sales will be shown soon."
            )}
          </div>
        )}
      </Translation>
    ),
    noBsrEstSales: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noBsrEstSales',
              "This listing ranks in a subcategory, but because it doesn't have a BSR we can't estimate sales for it at this time."
            )}
          </div>
        )}
      </Translation>
    ),
    rankFromApi: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.rankFromApi',
              "Jungle Scout uses rank to calculate estimated sales/revenue. The best sellers rank for the parent category is not shown on this product's listing page, only through Amazon's API. Historically, in this particular scenario, ranks seem to fluctuate greatly and therefore the estimated sales/revenue should be used with caution. We recommend tracking this product in the Product Tracker to get more accurate monthly sales."
            )}
          </div>
        )}
      </Translation>
    ),
    noSalesEstRevenue: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noSalesEstRevenue',
              'We estimate monthly revenue by multiplying the monthly unit sales by the price, since we were unable to estimate the monthly sales, we do not have a revenue estimate.'
            )}
          </div>
        )}
      </Translation>
    ),
    noPriceEstRevenue: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.nullProductData.noPriceEstRevenue',
              'We estimate monthly revenue by multiplying the monthly unit sales by the price, since this product did not have a buy box price, we do not have a revenue estimate.'
            )}
          </div>
        )}
      </Translation>
    ),
    sharedBsr: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:DATABASE_TOOLTIPS.nullProductData.sharedBsr',
            'Amazon does not provide sufficient product information about this variant, unable to generate differentiating details.'
          )
        }
      </Translation>
    ),
    noVariantDifferences: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:DATABASE_TOOLTIPS.nullProductData.noVariantDifferencesData',
            'Amazon does not provide sufficient product information about this variant. No variant differences data for this variant.'
          )
        }
      </Translation>
    ),
    noParentRank: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:DATABASE_TOOLTIPS.nullProductData.noParentRank',
            'Amazon does not provide rank data for parent ASINs with unique BSRs.'
          )
        }
      </Translation>
    )
  },
  exceedFilterRange: {
    estRevenue: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.exceedFilterRange.estRevenue',
              "This revenue value is a sum of unique BSR variant ASINs. Therefore your pre-determinded filters may only apply to this parent's variants."
            )}
          </div>
        )}
      </Translation>
    ),
    estSales: (
      <Translation ns="constants">
        {t => (
          <div className="tooltip-max">
            {t(
              'constants:DATABASE_TOOLTIPS.exceedFilterRange.estSales',
              "This sales value is a sum of unique BSR variant ASINs. Therefore your pre-determined filters may only apply to this parent's variants."
            )}
          </div>
        )}
      </Translation>
    )
  }
}
