import React from 'react'
import { Translation } from 'react-i18next'

import { parseCurrency } from 'helpers/currency'
import TrackerRecommendedCheckAll from '../../components/product_tracker/TrackerRecommendedCheckAll'

const DISPLAY_NAMES = {
  title: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.productTitle', 'Product Title')}</span>}
    </Translation>
  ),
  subTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.asin&Brand', 'ASIN | Brand')}</span>}
    </Translation>
  ),
  groupName: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.groupName', 'Group Name')}</span>}
    </Translation>
  ),
  price: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgPrice', 'Avg Price')}</span>}
    </Translation>
  ),
  avgFees: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgFees', 'Avg Fees')}</span>}
    </Translation>
  ),
  fees: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.amazonFees', 'Amazon Fees')}</span>}
    </Translation>
  ),
  net: (
    <Translation ns="common">
      {t => (
        <span>{t('common:MetricName.avgNetRevenue', 'Avg Net Revenue')}</span>
      )}
    </Translation>
  ),
  sales: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.unitsSold', 'Units Sold')}</span>}
    </Translation>
  ),
  salesSubTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.dailyAvg', 'Daily Avg')}</span>}
    </Translation>
  ),
  revenue: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.revenue', 'Revenue')}</span>}
    </Translation>
  ),
  revenueSubTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.dailyAvg', 'Daily Avg')}</span>}
    </Translation>
  ),
  rank: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.BSR', 'BSR')}</span>}
    </Translation>
  ),
  rankSubTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.dailyAvg', 'Daily Avg')}</span>}
    </Translation>
  ),
  reviews: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.reviews', 'Reviews')}</span>}
    </Translation>
  ),
  avgReviews: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgReviews', 'Avg Reviews')}</span>}
    </Translation>
  ),
  rating: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.starRating', 'Star Rating')}</span>}
    </Translation>
  ),
  avgRating: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgRating', 'Avg Rating')}</span>}
    </Translation>
  ),
  tier: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgSizeTier', 'Avg Size Tier')}</span>}
    </Translation>
  ),
  dimensions: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.dimensions', 'Dimensions')}</span>}
    </Translation>
  ),
  avgWeight: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgWeight', 'Avg Weight')}</span>}
    </Translation>
  ),
  weight: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.weight', 'Weight')}</span>}
    </Translation>
  ),
  avgListingQualityScore: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.avgLQS', 'Avg LQS')}</span>}
    </Translation>
  ),
  listingQualityScore: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.LQS', 'LQS')}</span>}
    </Translation>
  ),
  averageMonthlyRevenue: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.revenue', 'Revenue')}</span>}
    </Translation>
  ),
  averageMonthlyRevenueSubTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.monthlyAvg', 'Monthly Avg')}</span>}
    </Translation>
  ),
  averageMonthlySales: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.unitsSold', 'Units Sold')}</span>}
    </Translation>
  ),
  averageMonthlySalesSubTitle: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.monthlyAvg', 'Monthly Avg')}</span>}
    </Translation>
  ),
  variantDifferences: (
    <Translation ns="common">
      {t => (
        <span>
          {t('common:MetricName.variantDifferences', 'Variant Differences')}
        </span>
      )}
    </Translation>
  ),
  listedAt: (
    <Translation ns="common">
      {t => (
        <span>
          {t('common:MetricName.dateFirstAvailable', 'Date First Available')}
        </span>
      )}
    </Translation>
  ),
  category: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.category', 'Category')}</span>}
    </Translation>
  )
}

export const TRACKER_TABLE_COLUMNS = [
  {
    index: 1,
    name: 'name',
    value: 'name',
    displayName: 'Product Information',
    className: 'table__cell--header table__cell--product-name'
  },
  {
    index: 2,
    name: 'variantDifferences',
    value: 'variantDifferences',
    displayName: DISPLAY_NAMES.variantDifferences,
    className: 'table__cell--header'
  },
  {
    index: 3,
    name: 'avg_price',
    value: 'avg_price',
    displayName: DISPLAY_NAMES.price,
    className: 'table__cell--header'
  },
  {
    index: 4,
    name: 'fees',
    value: 'fees',
    displayName: DISPLAY_NAMES.fees,
    className: 'table__cell--header'
  },
  {
    index: 5,
    name: 'net',
    value: 'net',
    displayName: DISPLAY_NAMES.net,
    className: 'table__cell--header'
  },
  {
    index: 6,
    name: 'avg_rank',
    value: 'avg_rank',
    displayName: DISPLAY_NAMES.rank,
    className: 'table__cell--header'
  },

  {
    index: 7,
    name: 'avg_sales',
    value: 'avg_sales',
    displayName: DISPLAY_NAMES.sales,
    className: 'table__cell--header'
  },
  {
    index: 8,
    name: 'listedAt',
    value: 'listedAt',
    displayName: DISPLAY_NAMES.listedAt,
    className: 'table__cell--header'
  },
  {
    index: 9,
    name: 'avg_revenue',
    value: 'avg_revenue',
    displayName: (
      <>
        {DISPLAY_NAMES.revenue} - {DISPLAY_NAMES.revenueSubTitle}
      </>
    ),
    displayCsvName: 'Revenue - Daily Avg',
    className: 'table__cell--header'
  },
  {
    index: 10,
    name: 'averageMonthlyRevenue',
    value: 'averageMonthlyRevenue',
    displayName: (
      <>
        {DISPLAY_NAMES.averageMonthlyRevenue}
        {' - '}
        {DISPLAY_NAMES.averageMonthlyRevenueSubTitle}
      </>
    ),
    displayCsvName: 'Revenue - Monthly Avg',
    className: 'table__cell--header'
  },
  {
    index: 11,
    name: 'averageMonthlySales',
    value: 'averageMonthlySales',
    displayName: DISPLAY_NAMES.averageMonthlySales,
    className: 'table__cell--header'
  },
  {
    index: 12,
    name: 'nReviews',
    value: 'nReviews',
    displayName: DISPLAY_NAMES.reviews,
    className: 'table__cell--header'
  },
  {
    index: 13,
    name: 'rating',
    value: 'rating',
    displayName: DISPLAY_NAMES.rating,
    className: 'table__cell--header table__cell--rating'
  },
  {
    index: 14,
    name: 'dimensions',
    value: 'dimensions',
    displayName: DISPLAY_NAMES.dimensions,
    className: 'table__cell--header table__cell--large'
  },
  {
    index: 15,
    name: 'weight',
    value: 'weight',
    displayName: DISPLAY_NAMES.weight,
    className: 'table__cell--header'
  },
  {
    index: 16,
    name: 'listingQualityScore',
    value: 'listingQualityScore',
    displayName: DISPLAY_NAMES.listingQualityScore,
    className: 'table__cell--header'
  },
  {
    index: 17,
    name: 'actions',
    value: 'actions',
    displayName: '',
    className: 'table__cell--header table__cell--actions'
  }
]

export const DATE_RANGE_PICKER_OPTIONS = [
  {
    id: '1',
    value: 1,
    name: (
      <Translation ns="product_tracker">
        {t => t('product_tracker:DateRangePicker.options.1m', '1M')}
      </Translation>
    )
  },
  {
    id: '3',
    value: 3,
    name: (
      <Translation ns="product_tracker">
        {t => t('product_tracker:DateRangePicker.options.3m', '3M')}
      </Translation>
    )
  },
  {
    id: '6',
    value: 6,
    name: (
      <Translation ns="product_tracker">
        {t => t('product_tracker:DateRangePicker.options.6m', '6M')}
      </Translation>
    )
  }
]

export const DATE_RANGES_DAYS = DATE_RANGE_PICKER_OPTIONS.map(
  item => item.value
)

export const AGGREGATION_CARD_GROUPS = [
  [
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgDailyUnitsSold', 'Avg Daily Units Sold')}
            </span>
          )}
        </Translation>
      ),
      type: 'round',
      field: 'sales',
      scopedByDate: true
    },
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgDailyRevenue', 'Avg Daily Revenue')}
            </span>
          )}
        </Translation>
      ),
      type: 'currency',
      field: 'revenue',
      className: 'aggregation-block--lower',
      scopedByDate: true
    }
  ],
  [
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>{t('common:MetricName.avgDailyBSR', 'Avg Daily BSR')}</span>
          )}
        </Translation>
      ),
      type: 'round',
      field: 'rank',
      scopedByDate: true
    },
    {
      name: (
        <Translation ns="common">
          {t => <span>{t('common:MetricName.avgLQS', 'Avg LQS')}</span>}
        </Translation>
      ),
      type: 'lqs',
      field: 'listingQualityScore',
      className: 'aggregation-block--lower'
    }
  ],
  [
    {
      name: (
        <Translation ns="common">
          {t => <span>{t('common:MetricName.avgPrice', 'Avg Price')}</span>}
        </Translation>
      ),
      type: 'currency',
      field: 'price',
      className: 'aggregation-block--horizontal',
      scopedByDate: true,
      inline: true
    },
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgAmazonFees', 'Avg Amazon Fees')}
            </span>
          )}
        </Translation>
      ),
      type: 'currency',
      field: 'fees',
      className: 'aggregation-block--horizontal',
      inline: true
    },
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgNetRevenue', 'Avg Net Revenue')}
            </span>
          )}
        </Translation>
      ),
      type: 'currency',
      field: 'net',
      className: 'aggregation-block--lower aggregation-block--horizontal',
      scopedByDate: true,
      inline: true
    }
  ],
  [
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgStarRating', 'Avg Star Rating')}
            </span>
          )}
        </Translation>
      ),
      type: 'rating',
      field: 'rating'
    },
    {
      name: (
        <Translation ns="common">
          {t => (
            <span>
              {t('common:MetricName.avgReviewCount', 'Avg Review Count')}
            </span>
          )}
        </Translation>
      ),
      type: 'round',
      field: 'reviews',
      className: 'aggregation-block--lower'
    }
  ],
  [
    {
      name: (
        <Translation ns="common">
          {t => <span>{t('common:MetricName.avgWeight', 'Avg Weight')}</span>}
        </Translation>
      ),
      type: 'weight',
      field: 'weight'
    },
    {
      name: (
        <Translation ns="common">
          {t => <span>{t('common:MetricName.avgTier', 'Avg Tier')}</span>}
        </Translation>
      ),
      type: 'tier',
      field: 'tier',
      className: 'aggregation-block--lower'
    }
  ]
]

export const RECOMMENDED_PRODUCTS_TABLE_COLUMNS = t => {
  return [
    {
      name: 'check-actions',
      displayName: <TrackerRecommendedCheckAll />,
      className: 'table__cell--header table__cell--check'
    },
    {
      name: 'name',
      displayName: t(
        'product_tracker:RecommendedProductsTable.productInformation',
        'Product Information'
      ),
      className: 'table__cell--header table__cell--product-name',
      isSortable: true
    },
    {
      name: 'category',
      displayName: t(
        'product_tracker:RecommendedProductsTable.category',
        'Category'
      ),
      className: 'table__cell--header',
      isSortable: true
    },
    {
      name: 'price',
      displayName: t('product_tracker:RecommendedProductsTable.price', 'Price'),
      className: 'table__cell--header',
      isSortable: true
    }
  ]
}

export const PRODUCT_CHART_LINES = [
  {
    accessor: 'units_sold',
    label: 'Units Sold',
    type: 'area',
    isActive: true,
    valueFormatter: value => value.toLocaleString()
  },
  {
    accessor: 'rank',
    label: 'Rank',
    type: 'linear',
    isActive: true,
    shouldReverseYAxis: true,
    valueFormatter: value => value.toLocaleString()
  },
  {
    accessor: 'buy_box',
    label: 'Buy Box',
    type: 'linear',
    isActive: false,
    valueFormatter: (value, currencyCode) => parseCurrency(value, currencyCode)
  }
]
