import { ProfitOverviewDataIds } from 'constants/data_attributes/profit_overview/profit_overview'
import React from 'react'
import moment from 'moment'
import { Translation } from 'react-i18next'

export const PROFIT_OVERVIEW_MINIMUM_DATE = moment(
  '1978-01-01',
  'YYYY-MM-DD',
  true
)

export const PPC_CAMPAIGNS_MODAL = 'ppcCampaigns'

export const PRODUCT_TABLE_COLUMNS_NAMES = [
  {
    index: 0,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.productInformation',
            'Product Information'
          )
        }
      </Translation>
    ),
    accessor: 'title',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    disableColumnSelect: true,
    sortable: true,
    size: 'xxx-large',
    subtitle: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.productInformationSubtitle',
            'Marketplace & ASIN | SKU'
          )
        }
      </Translation>
    ),
    originalName: 'Product Information'
  },
  {
    index: 1,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.totalSales',
            'Total Sales'
          )
        }
      </Translation>
    ),
    accessor: 'total_sales',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Total Sales'
  },
  {
    index: 2,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.totalUnits',
            'Total Units'
          )
        }
      </Translation>
    ),
    accessor: 'total_units',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Total Units'
  },
  {
    index: 3,
    name: (
      <Translation ns="constants">
        {t => t('constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.cogs', 'COGS')}
      </Translation>
    ),
    accessor: 'cogs',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'COGS'
  },
  {
    index: 4,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.operatingExpenses',
            'Operating Expenses'
          )
        }
      </Translation>
    ),
    accessor: 'opex',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Operating Expenses'
  },
  {
    index: 5,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.netProfit',
            'Net Profit'
          )
        }
      </Translation>
    ),
    accessor: 'net_profit',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Net Profit'
  },
  {
    index: 6,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.grossMargin',
            'Gross Margin'
          )
        }
      </Translation>
    ),
    accessor: 'gross_margin',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Gross Margin'
  },
  {
    index: 7,
    name: (
      <Translation ns="constants">
        {t => t('constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.roi', 'ROI')}
      </Translation>
    ),
    accessor: 'roi',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'ROI'
  },
  {
    index: 8,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.unitsShipped',
            'Units Shipped'
          )
        }
      </Translation>
    ),
    accessor: 'units_shipped',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'Units Shipped'
  },
  {
    index: 9,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.promoUnitsShipped',
            'Promo Units Shipped'
          )
        }
      </Translation>
    ),
    accessor: 'promo_units',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Promo Units Shipped'
  },
  {
    index: 10,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.pendingUnits',
            'Pending Units'
          )
        }
      </Translation>
    ),
    accessor: 'pending_units',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'Pending Units'
  },
  {
    index: 11,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.organicSales',
            'Organic Sales'
          )
        }
      </Translation>
    ),
    accessor: 'organic_sales',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'Organic Sales'
  },
  {
    index: 12,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.ppcSales',
            'PPC Sales'
          )
        }
      </Translation>
    ),
    accessor: 'ppc_sales',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'PPC Sales'
  },
  {
    index: 13,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.ppcRevenue',
            'PPC Revenue (%)'
          )
        }
      </Translation>
    ),
    accessor: 'ppc_revenue_percentage',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'large',
    textAlign: 'right',
    originalName: 'PPC Revenue Percentage'
  },
  {
    index: 14,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.returns',
            'Returns'
          )
        }
      </Translation>
    ),
    accessor: 'units_refunded',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'Units Refunded'
  },
  {
    index: 15,
    name: (
      <Translation ns="constants">
        {t =>
          t(
            'constants:PROFIT_OVERVIEW_PRODUCT_TABLE.columns.returnRate',
            'Return Rate'
          )
        }
      </Translation>
    ),
    accessor: 'refund_rate',
    dataId: ProfitOverviewDataIds.Products.ColumnSorting,
    sortable: true,
    size: 'medium',
    textAlign: 'right',
    originalName: 'Return Rate'
  }
]
